import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { classNames } from "../../helpers/common";
import defaultAvatar from "../../images/default-avatar.png";
import api from "../../services/api";
import Spinner from "../ui/Spinner";

export default function Avatar({ imageUrl }) {
	const { userData, setUserData } = useAuth();

	const [loading, setLoading] = useState(false);

	const updateAvatar = (event) => {
		const file = event.target.files[0];
		if (!file) return;

		setLoading(true);

		let formData = new FormData();
		formData.append("files", file);
		formData.append("refId", userData?.id);
		formData.append("source", "users-permissions");
		formData.append("ref", "user");
		formData.append("field", "avatar");

		api
			.post("upload", formData)
			.then((res) => {
				if (res.status === 200) {
					setUserData({ ...userData, avatar: res.data[0] });
				}
				setLoading(false);
			})
			.catch((e) => {
				console.error("Login Error", e.response.data);
				setLoading(false);
			});
	};
	return (
		<div className="flex contents-center items-center flex-column pb-8">
			<div className="relative rounded-full overflow-hidden text-center block mx-auto  w-26 h-26">
				<img
					className="relative rounded-full w-40 h-40"
					src={imageUrl || defaultAvatar}
					alt=""
				/>
				<form>
					<label
						htmlFor="desktop-user-photo"
						className={classNames(
							"absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100",
							loading && "opacity-100"
						)}
					>
						{loading ? (
							<Spinner className="z-50 mr-0" />
						) : (
							<>
								<span>Change</span>
								<span className="sr-only"> user photo</span>
							</>
						)}
						<input
							type="file"
							id="desktop-user-photo"
							name="files"
							className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
							onChange={(event) => updateAvatar(event)}
						/>
					</label>
				</form>
			</div>
		</div>
	);
}
