import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import api from "../../services/api";
import Notice from "../shared/Notice";
import Button from "../ui/Button";
import Input from "../ui/Input";
import Modal from "../ui/Modal";

export default function EditModal({ field, onClose }) {
	const {setUserData} = useAuth();
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const updateUserField = (event) => {
		event.preventDefault();
		setLoading(true);

		const formData = new FormData(event.currentTarget);

		api.put("users/updateme", formData)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setUserData(res.data);
					onClose();
				}
			})
			.catch((e) => {
				setLoading(false);
				if (e.response.status === 400) {
					setError(
						e.response?.data?.message[0]?.messages[0]?.message ||
							"Invalid email or password"
					);
				} else {
					setError("Something went wrong");
				}
			});
	};

	return (
		<Modal
			open={!!field}
			onClose={onClose}
			footer={false}
			header={true}
			headerTitle={field.title}
			bodyPadding
		>
			<form
				className="space-y-4"
				action="#"
				method="POST"
				onSubmit={updateUserField}
			>
				<Input
					label={field.label}
					name={field.name}
					defaultValue={field.value}
					type={field.type || "text"}
					//onChange={(value) => setPayload({ ...payload, [field.name]: value })}
				/>

				<Button
					label="Save Changes"
					size="large"
					type="submit"
					fullWidth
					className="mt-8"
					loading={loading}
				/>
			</form>
			{error && <Notice type="error" className="mt-4" message={error} />}
		</Modal>
	);
}
