import React from "react";

export default function SectionTitle({title, subtitle, className}) {
	return (
		<div className={className}>
			<h1 className="text-lg leading-6 font-medium text-white">
				{title}
			</h1>
			<p className="mt-1 text-sm text-gray-400">
				{subtitle}
			</p>
		</div>
	);
}
