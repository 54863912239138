import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import Input from "../ui/Input";
import EditModal from "./EditModal";
import Avatar from "./Avatar";
import SectionTitle from "../ui/SectionTitle";
import api from "../../services/api";

export default function AccountSettings() {
	const { userData: user } = useAuth();

	const [activeField, setActiveField] = useState(false);

	const fields = [
		{ name: "firstName", label: "First Name" },
		{ name: "lastName", label: "Last Name" },
		{ name: "email", label: "Email", disabled: true },
	];

	const resetPassword = () => {
		api
			.post("auth/forgot-password", { email: user?.email })
			.then((res) => {
				alert(
					"Email Sent!",
					"We've sent you a reset link to your email address"
				);
			})
			.catch((e) => {
				alert(
					"Request Failed!",
					"We were unable to send you the reset link. Please contact customer support"
				);
			});
	};

	return (
		<div className="my-[11rem]">
			<Avatar imageUrl={user?.avatar?.url} />
			<dl className="divide-y divide-gray-800">
				<SectionTitle
					title="Account"
					subtitle="Your personal account information"
					className="mb-6"
				/>

				{user && (
					<>
						{fields.map((field) => (
							<Field
								key={field.name}
								label={field.label}
								value={user[field.name]}
								actionName={field.actionName}
								disabled={field.disabled}
								onClick={() =>
									setActiveField({
										label: field.label,
										name: field.name,
										value: user[field.name],
										title: `Edit your ${field.label}`,
									})
								}
							/>
						))}
						{/* <Field
							value="**********"
							label="Password"
							actionName="Reset Password"
							onClick={resetPassword}
						/> */}
					</>
				)}
			</dl>

			<EditModal field={activeField} onClose={() => setActiveField(false)} />
		</div>
	);
}

const Field = ({ label, value, disabled, actionName, onClick }) => {
	return (
		<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
			<dt className="text-sm font-medium text-gray-300">{label}</dt>
			<dd className="mt-1 flex text-sm text-white sm:mt-0 sm:col-span-2">
				<span className="flex-grow">{value}</span>
				<span className="ml-4 flex-shrink-0">
					<button
						type="button"
						className="bg-transparent rounded-md font-medium text-primary hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
						onClick={onClick}
						disabled={disabled}
					>
						{actionName || "Update"}
					</button>
				</span>
			</dd>
		</div>
	);
};
