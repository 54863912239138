import { navigate } from "gatsby";
import * as React from "react";
import AccountSettings from "../../components/account/AccountSettings";
import Container from "../../components/layout/Container";
import Layout from "../../components/shared/layout";
import SectionTitle from "../../components/ui/SectionTitle";
import { useAuth } from "../../context/AuthContext";

const IndexPage = ({ params, category }) => {
	const { userData, authModal } = useAuth();

	return (
		<Layout transparentNavbar={false} seoTitle="Settings" loginRequired>
			<Container maxWidth="max-w-3xl">
				<AccountSettings />
			</Container>
		</Layout>
	);
};

export default IndexPage;
